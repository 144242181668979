
import { Component, Vue } from "vue-property-decorator";
import Answer from "@/components/QA/Answer.vue";

@Component({
  components: {
    Answer,
  }
})

export default class Account extends Vue {
  accountList: { QTwo: string; Ans: string[] }[] = [
    {
      QTwo: "更新帳號資訊",
      Ans: [
        "要修改您的其他帳戶資訊,因請依照下列步驟:",
        " 1.前往APP內的帳號",
        " 2.您可以修改帳號資訊",
        " 備註:若您要修改電話號碼,需登出重新註冊新帳號。",
      ],
    },
    {
      QTwo: "忘記密碼",
      Ans: [
        "要重置密碼,請依照下列步驟操作:",
        "1.前往登入頁面",
        "2.點選''忘記密碼''",
        "3.輸入您的電子信箱",
        "4.您將會收到如何重置密碼的信件請不要與其他人分享您的密碼，我們的客服絕對不會詢問您的密碼。",
      ],
    },
    {
      QTwo: "刪除帳號",
      Ans: [
        "請從下方提交刪除帳號的申請並寫下刪除帳號的原因，我們將盡快與您聯絡。師虎來了可在刪除帳號後依規定或法律許可保留部分資訊。",
      ],
    },
    {
      QTwo: "個資與隱私問題",
      Ans: [
        "在師虎來了，我們非常重視用戶數據的安全性和隱私。我們僅與相關方［第三方修繕服務提供者－師傅］共享您的訂單資訊，所有其他相關個人資訊都會被隱藏。如果您對我們如何存儲和保護您的數據或如何使用它們有疑問，請透過下面電子郵件告訴我們，我們會盡速與您聯繫。",
      ],
    },
    {
      QTwo: "未收到簡訊驗證碼",
      Ans: [
        "目前只接有效的臺灣電話號碼，如果您沒收到簡訊驗證碼，請確認您輸入的號碼是正確的且手機是有訊號的，如果確認完仍沒有收到訊息，請嘗試再次發送簡訊驗證碼。",
      ],
    },
  ];
  questionObj!: { QTwo: string; Ans: Array<string> };
  showQuestion = true;
  // viewWidth!: number;

  // mounted(): void {
  //   this.viewWidth = window.innerWidth;
  //   window.onresize = () => {
  //     this.viewWidth = window.innerWidth;
  //   };
  // }
  //methods
  getQuestion(questionItem: {QTwo:string; Ans:string[]}): void {
    this.questionObj = questionItem;

    this.showQuestion = false;

    if(window.innerWidth > 768){
        window.scroll({
          top: 300,
        });
    }

    // if (this.viewWidth > 768) {
    //   document.documentElement.scrollTop = 300;
    // } else {
    //   document.documentElement.scrollTop = 0;
    // }
  }
}
