
import { Component, Prop, Vue } from "vue-property-decorator";
import tigermaster from "fdtigermaster-client-sdk";
@Component
export default class Answer extends Vue {
  @Prop() questionObj: { QTwo: string; Ans: string[] };

  //data
  isHelp = true;

  //methods
  feedbackSubmitClick(res: string): void {
    tigermaster.enquire.createFeedback({
      type: "official_qa_helpful",
      cause: this.questionObj.QTwo,
      content: res,
    });

    this.isHelp = false;
  }
}
